import Block from "@/components/wrappers/block"
import Blocks from "@/components/Blocks"
import classNames from "classnames/bind"
import styles from "./content-blocks.module.css"

function contentBlocks({ block, settings }) {
  const { attrs, innerBlocks } = block
  const cx = classNames.bind(styles)

  if (innerBlocks.length === 0) {
    return null
  }

  const width = settings?.width ?? "full"
  const blockSettings = {
    "theme/content-block": { multiple: true }
  }

  const classes = {
    wrapper: true,
    "layout-half": attrs.layout === "half",
    "layout-third": attrs.layout === "third",
    "layout-60-40": attrs.layout === "60-40",
    "layout-40-60": attrs.layout === "40-60"
  }

  return (
    <Block
      block={block}
      width={width}
      gap={attrs.blockGap ?? "normal"}
      className={cx(classes)}>
      <Blocks blocks={innerBlocks} settings={blockSettings} />
    </Block>
  )
}

export default contentBlocks
